const t = {
    'а':'a',
    'б':'b',
    'в':'v',
    'г':'g',
    'д':'d',
    'е':'e',
    'ё':'e',
    'ж':'zh',
    'з':'z',
    'и':'i',
    'й':'y',
    'к':'k',
    'л':'l',
    'м':'m',
    'н':'n',
    'о':'o',
    'п':'p',
    'р':'r',
    'с':'s',
    'т':'t',
    'у':'u',
    'ф':'f',
    'х':'kh',
    'ц':'ts',
    'ч':'ch',
    'ш':'sh',
    'щ':'sch',
    'ы':'y`',
    'ь':'',
    'ъ':'',
    'э':'e',
    'ю':'yu',
    'я':'ya',
    'А':'A',
    'Б':'B',
    'В':'V',
    'Г':'G',
    'Ѓ':'G',
    'Д':'D',
    'Е':'E',
    'Ё':'E',
    'Ж':'Zh',
    'З':'Z',
    'И':'I',
    'Й':'Y',
    'К':'K',
    'Л':'L',
    'М':'M',
    'Н':'N',
    'О':'O',
    'П':'P',
    'Р':'R',
    'С':'S',
    'Т':'T',
    'У':'U',
    'Ф':'F',
    'Х':'Kh',
    'Ц':'Ts',
    'Ч':'Ch',
    'Ш':'Sh',
    'Щ':'Sch',
    'Ы':'Y',
    'Ь':'',
    'Ъ':'',
    'Э':'E',
    'Ю':'Yu',
    'Я':'Ya',
    'Є':'Ye',
    '—': '-',
};

const transliterate = s =>
    s
        .replace(/ье/ig, 'ye')
        .replace(/ЬЕ/ig, 'YE')
        .replace(/[ьъ]/ig, '')
        .replace(/ГАЙ/ig, 'GAJ')
        .replace(/ГАЙ/ig, 'GAJ')
        .replace(/МОСКВА/g, 'MOSCOW')
        .replace(/КРЫМ/g, 'THE CRIMEA')
        .replace(/САНКТ-ПЕТЕРБУРГ/g, 'ST.PETERSBURG')
        .replace(/СТ-ПЕТЕРБУРГ/g, 'ST.PETERSBURG')
        .replace(/СПБ/g, 'ST.PETERSBURG')
        .replace(/ЭРБО/g, 'HERBAUT')
        .replace(/[А-яёЁЄ]/g, m => t[m] || m);
;

export default transliterate;
