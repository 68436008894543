import React from 'react';
import { connect } from 'formik';
import Button from '../../../ui/Buttons/Button';


const SubmitButton = ({ formik, className, onClick, children, disabled, ...restProps }) => (
    <Button
        type="submit"
        disabled={!formik.dirty || !formik.isValid || formik.isSubmitting || disabled}
        className={`profile-edit-page-controls__save${className ? ' ' + className : ''}`}
        onClick={onClick}
        {...restProps}
    >
        {children}
    </Button>
);

export default connect(SubmitButton);