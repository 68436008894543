import React from 'react';

export const endpointGetUserInfo = '/api/owners/owner/public_full/';
export const endpointGetRolesInfo = '/api/owners/owner/owner_roles?profileId=';

export const judgeIcon = <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.6117 9.54C3.6117 10.35 1.7917 11.45 2.0317 12.18C2.2817 12.94 4.4017 12.75 4.8617 13.38C5.3317 14.02 4.4917 15.98 5.1317 16.44C5.7617 16.9 7.3717 15.51 8.1317 15.75C8.8617 15.99 9.3417 18.07 10.1517 18.07C10.9617 18.07 11.4417 15.99 12.1717 15.75C12.9317 15.5 14.5417 16.9 15.1717 16.44C15.8117 15.97 14.9817 14.01 15.4417 13.38C15.9017 12.75 18.0217 12.94 18.2717 12.18C18.5117 11.45 16.6917 10.35 16.6917 9.54C16.6917 8.73 18.5117 7.63 18.2717 6.9C18.0217 6.14 15.9017 6.33 15.4417 5.7C14.9717 5.06 15.8117 3.1 15.1717 2.64C14.5417 2.18 12.9317 3.57 12.1717 3.33C11.4317 3.08 10.9617 1 10.1417 1C9.3317 1 8.8617 3.08 8.1217 3.32C7.3617 3.57 5.7517 2.17 5.1217 2.63C4.4817 3.1 5.3217 5.06 4.8517 5.7C4.3917 6.33 2.2717 6.14 2.0217 6.9C1.7817 7.63 3.6117 8.73 3.6117 9.54Z"
        fill="#54A7FF"
    />
    <path d="M9.22169 12.66C9.02169 12.66 8.83169 12.58 8.69169 12.44L6.26169 10.01C5.97169 9.72001 5.97169 9.24001 6.26169 8.95001C6.55169 8.66001 7.03169 8.66001 7.32169 8.95001L9.22169 10.85L12.9717 7.10001C13.2617 6.81001 13.7417 6.81001 14.0317 7.10001C14.3217 7.39001 14.3217 7.87001 14.0317 8.16001L9.75169 12.44C9.61169 12.58 9.42169 12.66 9.22169 12.66Z"
        fill="white"
    />
</svg>;

export const actorIcon = <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.6117 9.54C3.6117 10.35 1.7917 11.45 2.0317 12.18C2.2817 12.94 4.4017 12.75 4.8617 13.38C5.3317 14.02 4.4917 15.98 5.1317 16.44C5.7617 16.9 7.3717 15.51 8.1317 15.75C8.8617 15.99 9.3417 18.07 10.1517 18.07C10.9617 18.07 11.4417 15.99 12.1717 15.75C12.9317 15.5 14.5417 16.9 15.1717 16.44C15.8117 15.97 14.9817 14.01 15.4417 13.38C15.9017 12.75 18.0217 12.94 18.2717 12.18C18.5117 11.45 16.6917 10.35 16.6917 9.54C16.6917 8.73 18.5117 7.63 18.2717 6.9C18.0217 6.14 15.9017 6.33 15.4417 5.7C14.9717 5.06 15.8117 3.1 15.1717 2.64C14.5417 2.18 12.9317 3.57 12.1717 3.33C11.4317 3.08 10.9617 1 10.1417 1C9.3317 1 8.8617 3.08 8.1217 3.32C7.3617 3.57 5.7517 2.17 5.1217 2.63C4.4817 3.1 5.3217 5.06 4.8517 5.7C4.3917 6.33 2.2717 6.14 2.0217 6.9C1.7817 7.63 3.6117 8.73 3.6117 9.54Z"
        fill="#00C48C"
    />
    <path d="M9.22169 12.66C9.02169 12.66 8.83169 12.58 8.69169 12.44L6.26169 10.01C5.97169 9.72001 5.97169 9.24001 6.26169 8.95001C6.55169 8.66001 7.03169 8.66001 7.32169 8.95001L9.22169 10.85L12.9717 7.10001C13.2617 6.81001 13.7417 6.81001 14.0317 7.10001C14.3217 7.39001 14.3217 7.87001 14.0317 8.16001L9.75169 12.44C9.61169 12.58 9.42169 12.66 9.22169 12.66Z"
        fill="white"
    />
</svg>;

export const applicantIcon = <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="1" width="13.3333" height="16" rx="2" fill="url(#paint0_linear_2917_5219)"/>
    <rect x="4.88232" y="3.82353" width="9.41177" height="0.941176" fill="white"/>
    <rect x="4.88232" y="6.64706" width="9.41177" height="0.941176" fill="white"/>
    <rect x="4.88232" y="9.47058" width="9.41177" height="0.941176" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_2917_5219" x1="9.66667" y1="1" x2="9.66667" y2="17" gradientUnits="userSpaceOnUse">
            <stop stopColor="#54A7FF"/>
            <stop offset="1" stopColor="#1D81EB"/>
        </linearGradient>
    </defs>
</svg>;

export const allbreedJudgeIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2219_2838)">
        <path
            d="M10 8.74758C10 8.74758 5.33984 8.56635 5.33984 14.4952C5.33984 18.534 8.21363 15.5825 10 15.5825"
            fill="#3366ff"/>
        <path
            d="M10 8.74758C10 8.74758 14.6602 8.56635 14.6602 14.4952C14.6602 18.534 11.7864 15.5825 10 15.5825"
            fill="#3366ff"/>
        <path
            d="M7.78245 7.86058C8.65205 7.79263 9.27212 6.65123 9.16742 5.31119C9.06271 3.97115 8.27289 2.93992 7.40329 3.00786C6.53369 3.07581 5.91361 4.21721 6.01832 5.55725C6.12302 6.89729 6.91285 7.92852 7.78245 7.86058Z"
            fill="#3366ff"/>
        <path
            d="M4.29515 10.1055C5.06869 9.84864 5.37435 8.67234 4.97787 7.47811C4.58138 6.28387 3.63289 5.52394 2.85935 5.78076C2.08582 6.03757 1.78015 7.21388 2.17664 8.40811C2.57312 9.60234 3.52161 10.3623 4.29515 10.1055Z"
            fill="#3366ff"/>
        <path
            d="M13.983 5.54655C14.0877 4.20651 13.4676 3.06511 12.598 2.99717C11.7284 2.92922 10.9386 3.96046 10.8339 5.3005C10.7292 6.64054 11.3492 7.78193 12.2188 7.84988C13.0884 7.91782 13.8783 6.88659 13.983 5.54655Z"
            fill="#3366ff"/>
        <path
            d="M17.8208 8.41997C18.2172 7.22573 17.9116 6.04943 17.138 5.79261C16.3645 5.5358 15.416 6.29573 15.0195 7.48996C14.623 8.6842 14.9287 9.8605 15.7022 10.1173C16.4758 10.3741 17.4243 9.6142 17.8208 8.41997Z"
            fill="#3366ff"/>
    </g>
    <defs>
        <clipPath id="clip0_2219_2838">
            <rect width="16" height="13.6181" fill="white"
                transform="translate(2 3)"/>
        </clipPath>
    </defs>
</svg>;

export const judgeNav = alias => [
    {
        id: 1,
        title: 'Собаки',
        to: `/user/${alias}/dogs`,
        exact: true,
        // disabled: true,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1662_3388)">
                <path d="M7.39692 17.5908C5.5 17.5908 2.86923 17.1892 1.16615 15.2646L1 15.0708V14.8077C1 14.5585 1 8.66 4.96 6.26462C5.40308 4.79692 6.45539 2 7.96462 2C9.26615 2 9.76462 3.62 9.94462 4.57538C10.9277 4.67231 12.8385 5.01846 13.8769 6.15385C14.2646 6.56923 14.5 7.08154 14.5692 7.63538C15.5246 8.06462 17.8923 9.10308 18.1831 9.22769C18.6677 9.46308 18.8615 9.78154 18.9308 10.0169C19.0554 10.4046 18.9862 10.8338 18.7231 11.3462C18.1831 12.3846 17.1862 13.6723 15.5385 13.9492C14.4862 14.1154 12.9908 14.24 12.4369 14.2815C12.0631 14.5585 11.1908 15.1262 10.0969 15.3477L10.3323 17.2723L9.70923 17.3969C9.59846 17.4108 8.67077 17.5908 7.39692 17.5908ZM2.38462 14.5446C4.22615 16.4 7.43846 16.2754 8.78154 16.1231L8.54615 14.0738L9.30769 14.0462C10.6092 14.0185 11.7169 13.0908 11.7169 13.0769L11.8969 12.9385L12.1185 12.9246C12.1323 12.9246 14.0985 12.7862 15.3169 12.5923C16.4108 12.4123 17.1031 11.4708 17.5046 10.7092C17.6154 10.5015 17.6154 10.4185 17.6154 10.4185C17.2831 10.3631 13.6138 8.72923 13.6138 8.72923L13.1708 8.53538L13.1985 8.05077C13.2262 7.66308 13.1154 7.35846 12.8523 7.08154C12.0354 6.19538 10.0277 5.94615 9.32154 5.91846L8.71231 5.90462L8.65692 5.29538C8.54615 4.28462 8.15846 3.38462 7.96462 3.38462C7.56308 3.48154 6.70462 5.21231 6.20615 6.92923L6.12308 7.20615L5.87385 7.34461C2.82769 9.00615 2.44 13.4231 2.38462 14.5446Z" fill="#8F989D"/>
                <path d="M11.4261 9.51812C11.8237 9.51812 12.1461 9.19577 12.1461 8.79812C12.1461 8.40048 11.8237 8.07812 11.4261 8.07812C11.0284 8.07812 10.7061 8.40048 10.7061 8.79812C10.7061 9.19577 11.0284 9.51812 11.4261 9.51812Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1662_3388">
                    <rect width="18" height="15.5908" fill="white" transform="translate(1 2)"/>
                </clipPath>
            </defs>
        </svg>,
    },
    {
        id: 2,
        title: 'Уведомления',
        to: `/user/${alias}/news-feed`,
        exact: false,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75049 9.70055C2.34549 9.70055 2.01549 9.38555 2.00049 8.98055C2.00049 8.80055 1.89549 4.70555 4.76049 2.36555C5.07549 2.09555 5.55549 2.15555 5.81049 2.47055C6.08049 2.78555 6.02049 3.26555 5.70549 3.52055C3.42549 5.39555 3.50049 8.90555 3.50049 8.93555C3.51549 9.35555 3.18549 9.70055 2.78049 9.70055C2.76549 9.70055 2.76549 9.70055 2.75049 9.70055Z" fill="#8F989D"/>
            <path d="M17.2855 9.70055C17.2705 9.70055 17.2705 9.70055 17.2555 9.70055C16.8505 9.70055 16.5205 9.35555 16.5355 8.93555C16.5355 8.90555 16.6105 5.39555 14.3305 3.52055C14.0155 3.25055 13.9705 2.78555 14.2255 2.47055C14.4805 2.15555 14.9605 2.09555 15.2755 2.36555C18.1255 4.70555 18.0355 8.81555 18.0355 8.98055C18.0205 9.38555 17.6905 9.70055 17.2855 9.70055Z" fill="#8F989D"/>
            <path d="M10.0255 4.975C9.60548 4.975 9.27548 4.645 9.27548 4.225V1.75C9.27548 1.33 9.60548 1 10.0255 1C10.4455 1 10.7755 1.33 10.7755 1.75V4.225C10.7755 4.645 10.4305 4.975 10.0255 4.975Z" fill="#8F989D"/>
            <path d="M17.0005 16.7356H3.03549V14.3956L3.26049 14.1706C4.29549 13.1356 4.85049 11.9506 4.85049 10.7656C4.85049 10.5406 4.83549 10.3006 4.83549 10.0756C4.79049 8.87559 4.71549 7.37559 5.84049 5.75559C6.86049 4.27059 8.30049 3.47559 10.0255 3.47559C11.7205 3.47559 13.2955 4.33059 14.2255 5.75559C15.3055 7.40559 15.2455 8.90559 15.2155 10.1056C15.2155 10.3306 15.2005 10.5406 15.2005 10.7656C15.2005 11.9656 15.7555 13.1356 16.7905 14.1856L17.0005 14.3956V16.7356ZM4.53549 15.2356H15.5005V15.0106C14.3155 13.7356 13.6855 12.2656 13.6855 10.7656C13.6855 10.5406 13.7005 10.3006 13.7005 10.0606C13.7455 8.92059 13.7755 7.84059 12.9655 6.59559C12.3055 5.57559 11.2105 4.97559 10.0255 4.97559C8.81049 4.97559 7.82049 5.51559 7.07049 6.59559C6.23049 7.79559 6.27549 8.87559 6.33549 10.0006C6.33549 10.2556 6.35049 10.5106 6.35049 10.7656C6.35049 12.2806 5.72049 13.7356 4.53549 15.0106V15.2356Z" fill="#8F989D"/>
            <path d="M10.0255 18.9993C8.48047 18.9993 7.22047 17.7393 7.22047 16.1943C7.22047 15.7743 7.55047 15.4443 7.97047 15.4443C8.39047 15.4443 8.72047 15.7743 8.72047 16.1943C8.72047 16.9143 9.30547 17.4993 10.0255 17.4993C10.7455 17.4993 11.3305 16.9143 11.3305 16.1943C11.3305 15.7743 11.6605 15.4443 12.0805 15.4443C12.5005 15.4443 12.8305 15.7743 12.8305 16.1943C12.8305 17.7393 11.5705 18.9993 10.0255 18.9993Z" fill="#8F989D"/>
        </svg>,
    },
    {
        id: 3,
        title: 'Документы',
        to: `/user/${alias}/uploaded-documents/`,
        exact: false,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1666_818)">
                <path d="M15.7451 16.7308H6.19555C5.14368 16.7308 4.29272 15.8798 4.29272 14.828V2.90282C4.29272 1.85095 5.1555 1 6.19555 1H15.7451C16.797 1 17.6479 1.85095 17.6479 2.90282V14.8398C17.6479 15.8798 16.797 16.7308 15.7451 16.7308ZM6.19555 2.41825C5.92372 2.41825 5.71098 2.63099 5.71098 2.90282V14.8398C5.71098 15.1116 5.92372 15.3244 6.19555 15.3244H15.7451C16.017 15.3244 16.2297 15.1116 16.2297 14.8398V2.90282C16.2297 2.63099 16.017 2.41825 15.7451 2.41825H6.19555Z" fill="#8F989D"/>
                <path d="M13.4524 18.9999H3.90282C2.85095 18.9999 2 18.1372 2 17.0971V5.16015C2 4.10828 2.85095 3.25732 3.90282 3.25732H4.56468C4.95469 3.25732 5.2738 3.57643 5.2738 3.96645C5.2738 4.35647 4.95469 4.67558 4.56468 4.67558H3.90282C3.63099 4.67558 3.41825 4.90013 3.41825 5.16015V17.0853C3.41825 17.3571 3.63099 17.5699 3.90282 17.5699H13.4524C13.7242 17.5699 13.937 17.3571 13.937 17.0853V16.2816C13.937 15.8916 14.2561 15.5725 14.6461 15.5725C15.0361 15.5725 15.3552 15.8916 15.3552 16.2816V17.0853C15.3434 18.1372 14.4924 18.9999 13.4524 18.9999Z" fill="#8F989D"/>
                <path d="M13.9606 5.14823H7.8976C7.50758 5.14823 7.18848 4.82913 7.18848 4.43911C7.18848 4.04909 7.50758 3.72998 7.8976 3.72998H13.9725C14.3625 3.72998 14.6816 4.04909 14.6816 4.43911C14.6816 4.82913 14.3625 5.14823 13.9606 5.14823Z" fill="#8F989D"/>
                <path d="M13.9606 7.96122H7.8976C7.50758 7.96122 7.18848 7.64211 7.18848 7.2521C7.18848 6.86208 7.50758 6.54297 7.8976 6.54297H13.9725C14.3625 6.54297 14.6816 6.86208 14.6816 7.2521C14.6816 7.64211 14.3625 7.96122 13.9606 7.96122Z" fill="#8F989D"/>
                <path d="M10.9232 10.7742H7.8976C7.50758 10.7742 7.18848 10.4551 7.18848 10.0651C7.18848 9.67506 7.50758 9.35596 7.8976 9.35596H10.935C11.325 9.35596 11.6442 9.67506 11.6442 10.0651C11.6442 10.4551 11.325 10.7742 10.9232 10.7742Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1666_818">
                    <rect width="15.6481" height="18" fill="white" transform="translate(2 1)"/>
                </clipPath>
            </defs>
        </svg>,
    },
    {
        id: 4,
        title: 'Фотогалерея',
        to: `/user/${alias}/gallery`,
        exact: false,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1672_2123)">
                <path d="M16.9231 17.9646H3.07692C1.92769 17.9646 1 17.1615 1 16.1646V7.30308C1 6.30615 1.92769 5.50308 3.07692 5.50308H5.04308V3.8C5.04308 2.78923 5.65231 2 6.44154 2H13.5585C14.3477 2 14.9569 2.78923 14.9569 3.8V5.50308H16.9231C18.0723 5.50308 19 6.30615 19 7.30308V16.1646C19 17.1615 18.0723 17.9646 16.9231 17.9646ZM3.07692 6.88769C2.67538 6.88769 2.38462 7.10923 2.38462 7.30308V16.1646C2.38462 16.3585 2.67538 16.58 3.07692 16.58H16.9231C17.3246 16.58 17.6154 16.3585 17.6154 16.1646V7.30308C17.6154 7.10923 17.3246 6.88769 16.9231 6.88769H13.5723V3.8C13.5723 3.55077 13.4892 3.38462 13.4477 3.35692L6.44154 3.38462C6.51077 3.38462 6.42769 3.55077 6.42769 3.8V6.88769H3.07692Z" fill="#8F989D"/>
                <path d="M10 15.5969C7.60466 15.5969 5.6385 13.6446 5.6385 11.2354C5.6385 8.82616 7.60466 6.8877 10 6.8877C12.3954 6.8877 14.3616 8.84 14.3616 11.2492C14.3616 13.6585 12.3954 15.5969 10 15.5969ZM10 8.27231C8.3662 8.27231 7.02312 9.60154 7.02312 11.2492C7.02312 12.8969 8.3662 14.2123 10 14.2123C11.6339 14.2123 12.977 12.8831 12.977 11.2354C12.977 9.5877 11.6339 8.27231 10 8.27231Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1672_2123">
                    <rect width="18" height="15.9646" fill="white" transform="translate(1 2)"/>
                </clipPath>
            </defs>
        </svg>,
    },
    {
        id: 5,
        title: 'Видеозаписи',
        to: `/user/${alias}/video`,
        exact: true,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1672_2124)">
                <path d="M16.9231 17.2308H3.07692C1.92769 17.2308 1 16.3031 1 15.1538V4.07692C1 2.92769 1.92769 2 3.07692 2H16.9231C18.0723 2 19 2.92769 19 4.07692V15.1538C19 16.3031 18.0723 17.2308 16.9231 17.2308ZM3.07692 3.38462C2.68923 3.38462 2.38462 3.68923 2.38462 4.07692V15.1538C2.38462 15.5415 2.68923 15.8462 3.07692 15.8462H16.9231C17.3108 15.8462 17.6154 15.5415 17.6154 15.1538V4.07692C17.6154 3.68923 17.3108 3.38462 16.9231 3.38462H3.07692Z" fill="#8F989D"/>
                <path d="M7.54926 13.8388C7.24464 13.8388 6.95388 13.7557 6.6908 13.6034C6.22003 13.3265 5.92926 12.828 5.92926 12.3019V6.92955C5.92926 6.38955 6.22003 5.90494 6.6908 5.62801C7.20311 5.3234 7.85388 5.30955 8.36618 5.60032L13.2539 8.28647C13.7662 8.5634 14.0708 9.06186 14.0708 9.6157C14.0708 10.1696 13.7662 10.668 13.2677 10.9449L8.36618 13.6311C8.11695 13.7695 7.84003 13.8388 7.54926 13.8388ZM7.54926 6.7634C7.49388 6.7634 7.43849 6.77724 7.39695 6.80494L7.31388 12.3157C7.31388 12.3711 7.35541 12.3988 7.39695 12.4265C7.46618 12.468 7.5908 12.4957 7.70157 12.4265L12.5893 9.72647C12.6308 9.71263 12.6862 9.67109 12.6862 9.6157C12.6862 9.56032 12.6308 9.51878 12.5893 9.50493L7.70157 6.80494C7.64618 6.77724 7.60464 6.7634 7.54926 6.7634Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1672_2124">
                    <rect width="18" height="15.2308" fill="white" transform="translate(1 2)"/>
                </clipPath>
            </defs>
        </svg>,
    },
    {
        id: 6,
        title: 'Cтраница специалиста',
        to: `/user/${alias}`,
        exact: true,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1669_1909)">
                <path d="M16.9231 18.6154H3.07692C1.92769 18.6154 1 17.6185 1 16.4V4.21538C1 2.99692 1.92769 2 3.07692 2H16.9231C18.0723 2 19 2.99692 19 4.21538V16.4C19 17.6185 18.0723 18.6154 16.9231 18.6154ZM3.07692 3.38462C2.68923 3.38462 2.38462 3.75846 2.38462 4.21538V16.4C2.38462 16.8569 2.68923 17.2308 3.07692 17.2308H16.9231C17.3108 17.2308 17.6154 16.8569 17.6154 16.4V4.21538C17.6154 3.75846 17.3108 3.38462 16.9231 3.38462H3.07692Z" fill="#8F989D"/>
                <path d="M18.3077 8.17563H1.69231C1.30462 8.17563 1 7.87102 1 7.48332C1 7.09563 1.30462 6.79102 1.69231 6.79102H18.3077C18.6954 6.79102 19 7.09563 19 7.48332C19 7.87102 18.6954 8.17563 18.3077 8.17563Z" fill="#8F989D"/>
                <path d="M15.4692 11.1112H4.33688C3.94919 11.1112 3.64458 10.8066 3.64458 10.4189C3.64458 10.0312 3.94919 9.72656 4.33688 9.72656H15.4692C15.8569 9.72656 16.1615 10.0312 16.1615 10.4189C16.1615 10.8066 15.8569 11.1112 15.4692 11.1112Z" fill="#8F989D"/>
                <path d="M15.4692 14.0877H4.33688C3.94919 14.0877 3.64458 13.7831 3.64458 13.3954C3.64458 13.0077 3.94919 12.7031 4.33688 12.7031H15.4692C15.8569 12.7031 16.1615 13.0077 16.1615 13.3954C16.1615 13.7831 15.8569 14.0877 15.4692 14.0877Z" fill="#8F989D"/>
                <path d="M15.843 5.794C16.2942 5.794 16.66 5.42825 16.66 4.97708C16.66 4.52591 16.2942 4.16016 15.843 4.16016C15.3919 4.16016 15.0261 4.52591 15.0261 4.97708C15.0261 5.42825 15.3919 5.794 15.843 5.794Z" fill="#8F989D"/>
                <path d="M13.3923 5.794C13.8435 5.794 14.2093 5.42825 14.2093 4.97708C14.2093 4.52591 13.8435 4.16016 13.3923 4.16016C12.9412 4.16016 12.5754 4.52591 12.5754 4.97708C12.5754 5.42825 12.9412 5.794 13.3923 5.794Z" fill="#8F989D"/>
                <path d="M10.9416 5.794C11.3927 5.794 11.7585 5.42825 11.7585 4.97708C11.7585 4.52591 11.3927 4.16016 10.9416 4.16016C10.4904 4.16016 10.1246 4.52591 10.1246 4.97708C10.1246 5.42825 10.4904 5.794 10.9416 5.794Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1669_1909">
                    <rect width="18" height="16.6154" fill="white" transform="translate(1 2)"/>
                </clipPath>
            </defs>
        </svg>,
    },
];

export const userNav = alias => [
    {
        id: 1,
        title: 'Собаки',
        to: `/user/${alias}/dogs`,
        exact: true,
        // disabled: true,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1662_3388)">
                <path d="M7.39692 17.5908C5.5 17.5908 2.86923 17.1892 1.16615 15.2646L1 15.0708V14.8077C1 14.5585 1 8.66 4.96 6.26462C5.40308 4.79692 6.45539 2 7.96462 2C9.26615 2 9.76462 3.62 9.94462 4.57538C10.9277 4.67231 12.8385 5.01846 13.8769 6.15385C14.2646 6.56923 14.5 7.08154 14.5692 7.63538C15.5246 8.06462 17.8923 9.10308 18.1831 9.22769C18.6677 9.46308 18.8615 9.78154 18.9308 10.0169C19.0554 10.4046 18.9862 10.8338 18.7231 11.3462C18.1831 12.3846 17.1862 13.6723 15.5385 13.9492C14.4862 14.1154 12.9908 14.24 12.4369 14.2815C12.0631 14.5585 11.1908 15.1262 10.0969 15.3477L10.3323 17.2723L9.70923 17.3969C9.59846 17.4108 8.67077 17.5908 7.39692 17.5908ZM2.38462 14.5446C4.22615 16.4 7.43846 16.2754 8.78154 16.1231L8.54615 14.0738L9.30769 14.0462C10.6092 14.0185 11.7169 13.0908 11.7169 13.0769L11.8969 12.9385L12.1185 12.9246C12.1323 12.9246 14.0985 12.7862 15.3169 12.5923C16.4108 12.4123 17.1031 11.4708 17.5046 10.7092C17.6154 10.5015 17.6154 10.4185 17.6154 10.4185C17.2831 10.3631 13.6138 8.72923 13.6138 8.72923L13.1708 8.53538L13.1985 8.05077C13.2262 7.66308 13.1154 7.35846 12.8523 7.08154C12.0354 6.19538 10.0277 5.94615 9.32154 5.91846L8.71231 5.90462L8.65692 5.29538C8.54615 4.28462 8.15846 3.38462 7.96462 3.38462C7.56308 3.48154 6.70462 5.21231 6.20615 6.92923L6.12308 7.20615L5.87385 7.34461C2.82769 9.00615 2.44 13.4231 2.38462 14.5446Z" fill="#8F989D"/>
                <path d="M11.4261 9.51812C11.8237 9.51812 12.1461 9.19577 12.1461 8.79812C12.1461 8.40048 11.8237 8.07812 11.4261 8.07812C11.0284 8.07812 10.7061 8.40048 10.7061 8.79812C10.7061 9.19577 11.0284 9.51812 11.4261 9.51812Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1662_3388">
                    <rect width="18" height="15.5908" fill="white" transform="translate(1 2)"/>
                </clipPath>
            </defs>
        </svg>,
    },
    {
        id: 2,
        title: 'Уведомления',
        to: `/user/${alias}/news-feed`,
        exact: false,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75049 9.70055C2.34549 9.70055 2.01549 9.38555 2.00049 8.98055C2.00049 8.80055 1.89549 4.70555 4.76049 2.36555C5.07549 2.09555 5.55549 2.15555 5.81049 2.47055C6.08049 2.78555 6.02049 3.26555 5.70549 3.52055C3.42549 5.39555 3.50049 8.90555 3.50049 8.93555C3.51549 9.35555 3.18549 9.70055 2.78049 9.70055C2.76549 9.70055 2.76549 9.70055 2.75049 9.70055Z" fill="#8F989D"/>
            <path d="M17.2855 9.70055C17.2705 9.70055 17.2705 9.70055 17.2555 9.70055C16.8505 9.70055 16.5205 9.35555 16.5355 8.93555C16.5355 8.90555 16.6105 5.39555 14.3305 3.52055C14.0155 3.25055 13.9705 2.78555 14.2255 2.47055C14.4805 2.15555 14.9605 2.09555 15.2755 2.36555C18.1255 4.70555 18.0355 8.81555 18.0355 8.98055C18.0205 9.38555 17.6905 9.70055 17.2855 9.70055Z" fill="#8F989D"/>
            <path d="M10.0255 4.975C9.60548 4.975 9.27548 4.645 9.27548 4.225V1.75C9.27548 1.33 9.60548 1 10.0255 1C10.4455 1 10.7755 1.33 10.7755 1.75V4.225C10.7755 4.645 10.4305 4.975 10.0255 4.975Z" fill="#8F989D"/>
            <path d="M17.0005 16.7356H3.03549V14.3956L3.26049 14.1706C4.29549 13.1356 4.85049 11.9506 4.85049 10.7656C4.85049 10.5406 4.83549 10.3006 4.83549 10.0756C4.79049 8.87559 4.71549 7.37559 5.84049 5.75559C6.86049 4.27059 8.30049 3.47559 10.0255 3.47559C11.7205 3.47559 13.2955 4.33059 14.2255 5.75559C15.3055 7.40559 15.2455 8.90559 15.2155 10.1056C15.2155 10.3306 15.2005 10.5406 15.2005 10.7656C15.2005 11.9656 15.7555 13.1356 16.7905 14.1856L17.0005 14.3956V16.7356ZM4.53549 15.2356H15.5005V15.0106C14.3155 13.7356 13.6855 12.2656 13.6855 10.7656C13.6855 10.5406 13.7005 10.3006 13.7005 10.0606C13.7455 8.92059 13.7755 7.84059 12.9655 6.59559C12.3055 5.57559 11.2105 4.97559 10.0255 4.97559C8.81049 4.97559 7.82049 5.51559 7.07049 6.59559C6.23049 7.79559 6.27549 8.87559 6.33549 10.0006C6.33549 10.2556 6.35049 10.5106 6.35049 10.7656C6.35049 12.2806 5.72049 13.7356 4.53549 15.0106V15.2356Z" fill="#8F989D"/>
            <path d="M10.0255 18.9993C8.48047 18.9993 7.22047 17.7393 7.22047 16.1943C7.22047 15.7743 7.55047 15.4443 7.97047 15.4443C8.39047 15.4443 8.72047 15.7743 8.72047 16.1943C8.72047 16.9143 9.30547 17.4993 10.0255 17.4993C10.7455 17.4993 11.3305 16.9143 11.3305 16.1943C11.3305 15.7743 11.6605 15.4443 12.0805 15.4443C12.5005 15.4443 12.8305 15.7743 12.8305 16.1943C12.8305 17.7393 11.5705 18.9993 10.0255 18.9993Z" fill="#8F989D"/>
        </svg>,
    },
    {
        id: 3,
        title: 'Документы',
        to: `/user/${alias}/uploaded-documents/`,
        exact: false,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1666_818)">
                <path d="M15.7451 16.7308H6.19555C5.14368 16.7308 4.29272 15.8798 4.29272 14.828V2.90282C4.29272 1.85095 5.1555 1 6.19555 1H15.7451C16.797 1 17.6479 1.85095 17.6479 2.90282V14.8398C17.6479 15.8798 16.797 16.7308 15.7451 16.7308ZM6.19555 2.41825C5.92372 2.41825 5.71098 2.63099 5.71098 2.90282V14.8398C5.71098 15.1116 5.92372 15.3244 6.19555 15.3244H15.7451C16.017 15.3244 16.2297 15.1116 16.2297 14.8398V2.90282C16.2297 2.63099 16.017 2.41825 15.7451 2.41825H6.19555Z" fill="#8F989D"/>
                <path d="M13.4524 18.9999H3.90282C2.85095 18.9999 2 18.1372 2 17.0971V5.16015C2 4.10828 2.85095 3.25732 3.90282 3.25732H4.56468C4.95469 3.25732 5.2738 3.57643 5.2738 3.96645C5.2738 4.35647 4.95469 4.67558 4.56468 4.67558H3.90282C3.63099 4.67558 3.41825 4.90013 3.41825 5.16015V17.0853C3.41825 17.3571 3.63099 17.5699 3.90282 17.5699H13.4524C13.7242 17.5699 13.937 17.3571 13.937 17.0853V16.2816C13.937 15.8916 14.2561 15.5725 14.6461 15.5725C15.0361 15.5725 15.3552 15.8916 15.3552 16.2816V17.0853C15.3434 18.1372 14.4924 18.9999 13.4524 18.9999Z" fill="#8F989D"/>
                <path d="M13.9606 5.14823H7.8976C7.50758 5.14823 7.18848 4.82913 7.18848 4.43911C7.18848 4.04909 7.50758 3.72998 7.8976 3.72998H13.9725C14.3625 3.72998 14.6816 4.04909 14.6816 4.43911C14.6816 4.82913 14.3625 5.14823 13.9606 5.14823Z" fill="#8F989D"/>
                <path d="M13.9606 7.96122H7.8976C7.50758 7.96122 7.18848 7.64211 7.18848 7.2521C7.18848 6.86208 7.50758 6.54297 7.8976 6.54297H13.9725C14.3625 6.54297 14.6816 6.86208 14.6816 7.2521C14.6816 7.64211 14.3625 7.96122 13.9606 7.96122Z" fill="#8F989D"/>
                <path d="M10.9232 10.7742H7.8976C7.50758 10.7742 7.18848 10.4551 7.18848 10.0651C7.18848 9.67506 7.50758 9.35596 7.8976 9.35596H10.935C11.325 9.35596 11.6442 9.67506 11.6442 10.0651C11.6442 10.4551 11.325 10.7742 10.9232 10.7742Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1666_818">
                    <rect width="15.6481" height="18" fill="white" transform="translate(2 1)"/>
                </clipPath>
            </defs>
        </svg>,
    },
    {
        id: 4,
        title: 'Фотогалерея',
        to: `/user/${alias}/gallery`,
        exact: false,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1672_2123)">
                <path d="M16.9231 17.9646H3.07692C1.92769 17.9646 1 17.1615 1 16.1646V7.30308C1 6.30615 1.92769 5.50308 3.07692 5.50308H5.04308V3.8C5.04308 2.78923 5.65231 2 6.44154 2H13.5585C14.3477 2 14.9569 2.78923 14.9569 3.8V5.50308H16.9231C18.0723 5.50308 19 6.30615 19 7.30308V16.1646C19 17.1615 18.0723 17.9646 16.9231 17.9646ZM3.07692 6.88769C2.67538 6.88769 2.38462 7.10923 2.38462 7.30308V16.1646C2.38462 16.3585 2.67538 16.58 3.07692 16.58H16.9231C17.3246 16.58 17.6154 16.3585 17.6154 16.1646V7.30308C17.6154 7.10923 17.3246 6.88769 16.9231 6.88769H13.5723V3.8C13.5723 3.55077 13.4892 3.38462 13.4477 3.35692L6.44154 3.38462C6.51077 3.38462 6.42769 3.55077 6.42769 3.8V6.88769H3.07692Z" fill="#8F989D"/>
                <path d="M10 15.5969C7.60466 15.5969 5.6385 13.6446 5.6385 11.2354C5.6385 8.82616 7.60466 6.8877 10 6.8877C12.3954 6.8877 14.3616 8.84 14.3616 11.2492C14.3616 13.6585 12.3954 15.5969 10 15.5969ZM10 8.27231C8.3662 8.27231 7.02312 9.60154 7.02312 11.2492C7.02312 12.8969 8.3662 14.2123 10 14.2123C11.6339 14.2123 12.977 12.8831 12.977 11.2354C12.977 9.5877 11.6339 8.27231 10 8.27231Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1672_2123">
                    <rect width="18" height="15.9646" fill="white" transform="translate(1 2)"/>
                </clipPath>
            </defs>
        </svg>,
    },
    {
        id: 5,
        title: 'Видеозаписи',
        to: `/user/${alias}/video`,
        exact: true,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1672_2124)">
                <path d="M16.9231 17.2308H3.07692C1.92769 17.2308 1 16.3031 1 15.1538V4.07692C1 2.92769 1.92769 2 3.07692 2H16.9231C18.0723 2 19 2.92769 19 4.07692V15.1538C19 16.3031 18.0723 17.2308 16.9231 17.2308ZM3.07692 3.38462C2.68923 3.38462 2.38462 3.68923 2.38462 4.07692V15.1538C2.38462 15.5415 2.68923 15.8462 3.07692 15.8462H16.9231C17.3108 15.8462 17.6154 15.5415 17.6154 15.1538V4.07692C17.6154 3.68923 17.3108 3.38462 16.9231 3.38462H3.07692Z" fill="#8F989D"/>
                <path d="M7.54926 13.8388C7.24464 13.8388 6.95388 13.7557 6.6908 13.6034C6.22003 13.3265 5.92926 12.828 5.92926 12.3019V6.92955C5.92926 6.38955 6.22003 5.90494 6.6908 5.62801C7.20311 5.3234 7.85388 5.30955 8.36618 5.60032L13.2539 8.28647C13.7662 8.5634 14.0708 9.06186 14.0708 9.6157C14.0708 10.1696 13.7662 10.668 13.2677 10.9449L8.36618 13.6311C8.11695 13.7695 7.84003 13.8388 7.54926 13.8388ZM7.54926 6.7634C7.49388 6.7634 7.43849 6.77724 7.39695 6.80494L7.31388 12.3157C7.31388 12.3711 7.35541 12.3988 7.39695 12.4265C7.46618 12.468 7.5908 12.4957 7.70157 12.4265L12.5893 9.72647C12.6308 9.71263 12.6862 9.67109 12.6862 9.6157C12.6862 9.56032 12.6308 9.51878 12.5893 9.50493L7.70157 6.80494C7.64618 6.77724 7.60464 6.7634 7.54926 6.7634Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1672_2124">
                    <rect width="18" height="15.2308" fill="white" transform="translate(1 2)"/>
                </clipPath>
            </defs>
        </svg>,
    },
    {
        id: 6,
        title: 'Cтраница пользователя',
        to: `/user/${alias}`,
        exact: true,
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1669_1909)">
                <path d="M16.9231 18.6154H3.07692C1.92769 18.6154 1 17.6185 1 16.4V4.21538C1 2.99692 1.92769 2 3.07692 2H16.9231C18.0723 2 19 2.99692 19 4.21538V16.4C19 17.6185 18.0723 18.6154 16.9231 18.6154ZM3.07692 3.38462C2.68923 3.38462 2.38462 3.75846 2.38462 4.21538V16.4C2.38462 16.8569 2.68923 17.2308 3.07692 17.2308H16.9231C17.3108 17.2308 17.6154 16.8569 17.6154 16.4V4.21538C17.6154 3.75846 17.3108 3.38462 16.9231 3.38462H3.07692Z" fill="#8F989D"/>
                <path d="M18.3077 8.17563H1.69231C1.30462 8.17563 1 7.87102 1 7.48332C1 7.09563 1.30462 6.79102 1.69231 6.79102H18.3077C18.6954 6.79102 19 7.09563 19 7.48332C19 7.87102 18.6954 8.17563 18.3077 8.17563Z" fill="#8F989D"/>
                <path d="M15.4692 11.1112H4.33688C3.94919 11.1112 3.64458 10.8066 3.64458 10.4189C3.64458 10.0312 3.94919 9.72656 4.33688 9.72656H15.4692C15.8569 9.72656 16.1615 10.0312 16.1615 10.4189C16.1615 10.8066 15.8569 11.1112 15.4692 11.1112Z" fill="#8F989D"/>
                <path d="M15.4692 14.0877H4.33688C3.94919 14.0877 3.64458 13.7831 3.64458 13.3954C3.64458 13.0077 3.94919 12.7031 4.33688 12.7031H15.4692C15.8569 12.7031 16.1615 13.0077 16.1615 13.3954C16.1615 13.7831 15.8569 14.0877 15.4692 14.0877Z" fill="#8F989D"/>
                <path d="M15.843 5.794C16.2942 5.794 16.66 5.42825 16.66 4.97708C16.66 4.52591 16.2942 4.16016 15.843 4.16016C15.3919 4.16016 15.0261 4.52591 15.0261 4.97708C15.0261 5.42825 15.3919 5.794 15.843 5.794Z" fill="#8F989D"/>
                <path d="M13.3923 5.794C13.8435 5.794 14.2093 5.42825 14.2093 4.97708C14.2093 4.52591 13.8435 4.16016 13.3923 4.16016C12.9412 4.16016 12.5754 4.52591 12.5754 4.97708C12.5754 5.42825 12.9412 5.794 13.3923 5.794Z" fill="#8F989D"/>
                <path d="M10.9416 5.794C11.3927 5.794 11.7585 5.42825 11.7585 4.97708C11.7585 4.52591 11.3927 4.16016 10.9416 4.16016C10.4904 4.16016 10.1246 4.52591 10.1246 4.97708C10.1246 5.42825 10.4904 5.794 10.9416 5.794Z" fill="#8F989D"/>
            </g>
            <defs>
                <clipPath id="clip0_1669_1909">
                    <rect width="18" height="16.6154" fill="white" transform="translate(1 2)"/>
                </clipPath>
            </defs>
        </svg>,
    },
];